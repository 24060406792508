.hero {
  background-image: url("../images/bg.png");
  background-position: center;
  background-size: cover;
}
@media (min-width: 1920px) {
  .hero {
    padding: 0 80px;
  }
}
.footer {
  background-image: url("../images/footer.png");
  background-position: center;
  background-size: cover;
}
.bg-shadow {
  background-image: url("../images/Gradients\ \(1\).png");
  background-position: center;
  background-size: cover;
}
.slick-initialized .slick-slide {
  padding: 0 20px 0 20px !important;
}
@media (max-width: 640px) {
  .slick-initialized .slick-slide {
    padding: 0 10px 0 10px !important;
  }
}
/* .slick-initialized .slick-slide {
  background-color: white;
  border-radius: 16px;
  height: 904px !important;
} */
/* .slick-track {
  align-items: stretch !important;
  display: flex !important;
}

.slick-slide {
  width: 500px !important;
  height: auto !important;
} */
/* .slick-slide {
  height: auto !important; 
}
.slider-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px; 
}

.slider-content {
  flex-grow: 1; 
} */
